.name-input {
	position: relative;
	left:11%;
	width:calc(100% - 22%);
	text-align: center;
	margin:0;

	margin-bottom:15px;

	z-index: 20;
}
.name-input .name,
.name-input .birthday,
.name-input .birthday-ol {
	position: relative;
	width:calc(100% - 14%);

	font-size: 40px;
	
	border-radius: 10px;
	text-align:center;
	margin:0;

	border: 2px solid black;
}
.name-input .birthday-ol {
	position: absolute;
	display: block;
	
	width:100% !important;
	height:90%;
	line-height: 76px;
	border-width: 0px;
	text-align:center;

	pointer-events: none;
/*	background-color: rgba(0,255,255,0.5); */
	z-index: 10;
}
.name-input .birthday {
	width:100% !important;
}
.name-input .chklabel {
	position: relative;
	display: block;
	width:calc(98% - 30px);
	height:30px;

	text-align:right;
	font-size: 4vw;
	line-height: 30px;
	
	margin-top:10px;

	text-decoration: none;
}
.name-input .chklabel .cbox,
.name-input .chklabel .cbox-check{
	position: absolute;
	top:0px;
	right:-35px;
	display: block;
	width:25px;
	height:25px;

	border-radius: 5px;
	border-color: #4f2c1d;
	border-width: 1px;
	border-style: solid;

	background-color: transparent;
}
.name-input .chklabel .cbox-check {
	background-color: #b58500;
}