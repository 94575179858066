.cwrapcam {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  
  z-index: 1;
}
.not-allowed {cursor: not-allowed;}

.cwraplabel {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
.canvasstories {
  position: absolute;
  display: block;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
.canvasstories-2 {
  position: absolute;
  display: none;
  left: 0px;
  top: 0px;
  width: 100%;
  
  z-index: 1;
}
.imgwrapper {
  position: relative;
  width:100%;
  height:auto;

  overflow: hidden;
  margin-bottom:50px;
  z-index: 100;
}
.imgwrapper img {
  position: relative;
  display: block;
  margin-top:-55%;
  left:-50%;
  min-width: 200%;
}