.btn-dorshare,
.btn-hotowin,
.btn-dorshareMinBot,
.btn-dorshare-over {
	position: relative;

	display: inline-block;
	border-radius: 15px;
	border: 2px solid #ab2328;
	width: 80%;
	height: 50px;
  
	margin-left:10%;

	text-align: left;
	font-family: birra-body;
	font-size: 25px;
	line-height: 50px;
	font-weight: normal;
	text-decoration: none;
	text-indent: 45px;

	margin-bottom:40px;

	background-color: #ab2328;
	color: #fff6d8;
}
.btn-dorshareMinBot {
	margin-bottom:12px;
}
.btn-hotowin {
	text-indent: 0px;
	text-align: center;
}
.btn-dorshare svg,
.btn-dorshareMinBot svg,
.btn-dorshare-over svg {
	position: absolute;
	right:30px;
	top:12px;
	width:22px;
}



.howtowin {
	position: absolute;
	display: block;
	top:620px;
	width:calc(100% - 8%);
	height:470px;
  
	background-color: #ab2328;
	margin-left:4%;
	margin-right:4%;
	border-radius: 20px;
  
	z-index: 200;
  }
  .howtowin .header {
	position: relative;
	display: block;
	height:auto;
	padding-top:20px;
	padding-left:20px;
	padding-right:20px;
	padding-bottom: 0px;
  
	color:#fff6d8;
	font-size:11vw;
	line-height: 9vw;
	font-family: birra-body;
  }
  .howtowin .header .close {
	position: absolute;
	right:20px;
	top:20px;
  
	width:88px;
	height:89px;
  }
  .howtowin .header .close img {
	position: absolute;
	right:0;
	width:35%;
	height:35%;
  }
  .howtowin .txt {
	position: absolute;
	display: block;
	margin:20px;
	height:calc(100% - 32vw);
	font-family: arial;
	font-size:4.7vw;
	line-height: 6.0vw;
	color:#fff6d8;
  
  }
  .howtowin .txt ul {
	padding-left: 20px;
  }
  .howtowin .txt ul li {
	
  }



.stories {
	position: relative;
	display: block;
	z-index: 20;
	margin:0;
	padding:0;
	height:auto;

	padding-left:7%;
	padding-right:7%;
}
.stories::after {
	clear: both;
	content: "";
	display: table;
}

.stories .nameTitle {
	font-size:7vw;
	line-height: 7vw;
	color:#4f2c1d;
	margin:0;
	padding:0;
	margin-bottom:5px;
}
.stories .plname {
	font-size:12.2vw;
	line-height: 12vw;
	font-family: ChunkFive;
	color:#93272c;
	margin:0;
}
.stories .pldal {
	font-size:7vw;
	line-height: 7vw;
	color:#b58500;
	margin:0;
	margin-top:10px;
}
.brownblock {
	position: relative;
	display: block;

	z-index: 20;
	margin:0;
	padding:0;
	height:auto;

	padding-left:7%;
	padding-right:7%;

	font-family:birra-body,sans-serif;
	font-size: 4vw;
	line-height:5vw;
	
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	background-color:#bc8100;
	margin-top:20px;

	padding-top:30px;
	padding-bottom:40px;

	color:#fff9e0;
}
.brownblock::after {
	clear: both;
	content: "";
	display: table;
}
.celebrate,
.celebratebb {
	position: relative;
	display: block;

	font-size:8vw;
	line-height: 8vw;

	z-index: 20;
	margin:0;
	padding:0;
	height:auto;

	margin-top:30px;

	padding-left:7%;
	padding-right:7%;

	color:#4f2c1d;
}
.celebratebb {
	padding-left:0%;
	padding-right:0%;
	margin-top:0px;
	color:#fff9e0;
}
.celebrate-body,
.celebrate-bodybb {

	position: relative;
	display: block;

	font-size:8vw;
	line-height: 8vw;

	z-index: 20;
	margin:0;
	padding:0;
	height:auto;

	font-family: gothambook;
	font-weight: 400;
	font-size:4vw;
	line-height: 6.6vw;

	margin-top:20px;

	padding-left:7%;
	padding-right:7%;

	color:#4f2c1d;
}
.celebrate-bodybb {
	padding-left:0%;
	padding-right:0%;
	margin-top:0px;
	color:#fff9e0;
}
.signature {
	width:45%;
	margin-left:27%;
	margin-top:30px;
}
